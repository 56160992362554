import {
  TableCell,
  Paper,
  styled,
  Table,
  TableBody,
  tableCellClasses,
  TableContainer,
  TableHead,
  IconButton,
  TableRow,
  Skeleton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  setDeleteToken,
  galleryState,
  setDeleteKey,
  deleteImageAsync,
} from "../reduxSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    // fontWeight: "bold",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function GalleryList() {
  const { collection, loading, buttonLoading, deleteLoading, deleteKey } =
    useSelector(galleryState);
  const dispatch = useDispatch();
  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={60} />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow
                sx={{ background: "#000", textTransform: "capitalize" }}
              >
                <TableCell sx={{ color: "#fff" }}>Image</TableCell>
                <TableCell sx={{ color: "#fff" }} align="right">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collection?.map((row, i) => (
                <StyledTableRow
                  className="shadow-sm "
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    <img
                      src={row.location}
                      alt=".."
                      style={{ width: "100px" }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {deleteKey === row.id ? (
                      deleteLoading ? (
                        <LoadingButton
                          loading
                          loadingPosition="start"
                          startIcon={<DeleteIcon />}
                          variant="outlined"
                        >
                          Deleting...
                        </LoadingButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            dispatch(setDeleteToken({ value: row.id }));
                            dispatch(setDeleteKey({ value: row.key }));
                            dispatch(deleteImageAsync({}));
                          }}
                        >
                          <DeleteIcon
                            sx={{ color: "#5D5D5D", cursor: "pointer" }}
                          />
                        </IconButton>
                      )
                    ) : (
                      <IconButton
                        onClick={() => {
                          dispatch(setDeleteToken({ value: row.id }));
                          dispatch(setDeleteKey({ value: row.key }));
                          dispatch(deleteImageAsync({}));
                        }}
                      >
                        <DeleteIcon
                          sx={{ color: "#5D5D5D", cursor: "pointer" }}
                        />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
