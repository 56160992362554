import React from "react";
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { setEmail, setPassword,loginUserAsync } from "./reduxSlice";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    avatar: {
      margin: 1,
      backgroundColor: "blue",
    },
    form: {
      width: "100%",
      marginTop: 1,
    },
    submit: {
      margin: 2,
    },
  },
});

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  //password input start-----
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //password input end-----

  return (
    <Grid sx={{ display: "flex", height: "100vh" }} container component="main">
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)), url(https://images.unsplash.com/photo-1467384520991-15001a740e87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw0Njk1NzgxfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-dark"
      >
        <Typography
          variant="h2"
          component="h1"
          color="#fff"
          sx={{
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          WELCOME
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        component={Box}
        elevation={6}
        square
        sx={{ placeSelf: "center" }}
        className="bg-blue h-100 d-flex jusstify-content-center align-items-center "
      >
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: {
                xs: 3,
                md: 10,
              },
            }}
          >
            <Avatar sx={{ backgroundColor: "#0361c6", m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h4" className="">
              Sign in
            </Typography>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={(e) => {
                  dispatch(setEmail({ email: e.target.value }));
                }}
              />
              {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(e) => {
                  dispatch(setPassword({ password: e.target.value }));
                }}
              /> */}
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    dispatch(setPassword({ password: e.target.value }));
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(loginUserAsync({navigate }));
                }}
              >
                Sign In
              </Button>
              <Grid container className="mt-2">
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Box>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}
