import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

function createData(title, createdDate) {
  return { title, createdDate };
}

const rows = [
  createData("Homepage", "25-feb-2023"),
  createData("About us", "25-feb-2023"),
  createData("Contact us", "25-feb-2023"),
  createData("Blogs", "25-feb-2023"),
  createData("Events", "25-feb-2023"),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    // fontWeight: "bold",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function Pages() {
  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 0,
            },
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="h5">
            {" Pages"}
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              background: "grey",
              ml: "auto",
              mr: 3,
              display: {
                xs: "none",
                md: "block",
              },
            }}
          ></Divider>
          <Tooltip title="Add new row" arrow placement="top">
            <Button
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}
              className="py-2 px-md-5 rounded-1 fw-bold btn-blue"
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow
                  sx={{ background: "#000", textTransform: "capitalize" }}
                >
                  <TableCell sx={{ color: "#fff" }}>Image</TableCell>
                  <TableCell sx={{ color: "#fff" }} align="center">
                    Title
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Created Date
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Edit
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <StyledTableRow
                    className="shadow-sm "
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <img
                        src="https://images.unsplash.com/photo-1678737046425-c85475e7855f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt=".."
                        style={{ width: "50px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.title}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.createdDate}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <CreateIcon
                        sx={{ color: "#1976d2", cursor: "pointer" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <DeleteIcon
                        sx={{ color: "#5D5D5D", cursor: "pointer" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </>
  );
}
