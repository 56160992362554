import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function uploadImage(files,title, link ,folder,website) {
  const uploadImageDoc = httpsCallable(functions, "addGalleryCollection");
  return uploadImageDoc({files,title, link ,folder,website});
}

export function getGallery(awsFolder, website) {
  const getGalleryDoc = httpsCallable(functions, "getGalleryCollection");
  return getGalleryDoc({awsFolder, website});
}

export function deleteImage({deleteKey, deleteToken, website,awsFolder}) {
  const deleteImageDoc = httpsCallable(functions, "deleteImage");
  return deleteImageDoc({deleteKey, deleteToken, website,awsFolder});
}
