import { Container, Typography } from "@mui/material";
import React from "react";

export default function SettingsPage() {
  return (
    <>
      <Container className="p-5 d-flex flex-column gap-2">
        <Typography variant="h4" component="h5">
          {" Comming soon..."}
        </Typography>
      </Container>
    </>
  );
}
