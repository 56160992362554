import { Box, Container, Typography } from "@mui/material";
import React from "react";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import YouTubeIcon from "@mui/icons-material/YouTube";

export default function Footer() {
  return (
    <>
      <Box sx={{ color: "#0361c6", mt: "auto" }}>
        <Container
          maxWidth
          sx={{
            display: "flex",
            flexDirection: {
              md: "row",
              xs: "column",
            },
            justifyContent: "center",
            alignItems: "center",
            py: 1,
          }}
        >
          {/* <Box className="d-flex gap-2 align-items-center ">
            <Typography
              variant="body1"
              component="a"
              color="inherit"
              href="https://www.facebook.com/StAnthonyAshramJeppu/"
              target="_blank"
            >
              <FacebookIcon />
            </Typography>
            <Typography
              variant="body1"
              component="a"
              color="inherit"
              href="https://www.instagram.com/st_anthony_ashram_jeppu/"
              target="_blank"
            >
              <InstagramIcon />
            </Typography>
            <Typography
              variant="body1"
              component="a"
              color="inherit"
              href="https://www.youtube.com/channel/UCCQfj302mcHnT4orpAcSiHw"
              target="_blank"
            >
              <YouTubeIcon />
            </Typography>
          </Box> */}
          <Typography
            variant="subtitle2"
            component="h6"
            color="#0361c6"
            className="mx-auto"
          >
            Power by NuLark Solutions @ {new Date().getFullYear()}
            {/* {" St.Anthony"} */}
          </Typography>
        </Container>
      </Box>
    </>
  );
}
