import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function createNews(heading, date, body, files, website) {
  const createDoc = httpsCallable(functions, "createNews");
  return createDoc({
    heading,
    date,
    body,
    files,
    website,
  });
}

export function getNews(sessionToken, website) {
  const getDoc = httpsCallable(functions, "getNews");
  return getDoc({ sessionToken, website });
}


export function deleteNews(id, deleteKey, website) {
  const getDoc = httpsCallable(functions, "deleteNews");
  return getDoc({ id, deleteKey, website });
}

export function updateNews(id, deleteKey, heading, date, body, files, website) {
  const getDoc = httpsCallable(functions, "updateNews");
  return getDoc({
    id,
    deleteKey,
    heading,
    date,
    body,
    files,
    website,
  });
}


export function uploadImageToServer(image) {
  const createDoc = httpsCallable(functions, "uploadImageToServer");
  return createDoc({
    image
  });
}
