import * as React from "react";
import { Skeleton, Box } from "@mui/material";

export default function App() {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          justifyContent:"space-between",
          gap: 1,
          mb:1
        }}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ width: 250, mb: ".5" }}
          height={50}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={50}
          sx={{ width: 150, mb: ".5" }}
        />
      </Box>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={50}
        sx={{ mb: .2 }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={30}
        sx={{ mb: .5 }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={30}
        sx={{ mb: .5 }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={30}
        sx={{ mb: .5 }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={30}
        sx={{ mb: .5 }}
      />
    </Box>
  );
}
