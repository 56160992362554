import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Skeleton,
  IconButton,
} from "@mui/material";
import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import NewsForm from "./Form/NewsForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewsAsync,
  newsState,
  setFormModal,
  setID,
  setDeleteKey,
  deleteNewsAsync,
  setHeading,
  setDate,
  setBody,
} from "./reduxSlice";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    // fontWeight: "bold",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function News() {
  const dispatch = useDispatch();
  const { formModel, newsCollection, loading, Buttonloading, id } =
    useSelector(newsState);
  React.useEffect(() => {
    dispatch(getNewsAsync({}));
    return () => {
      dispatch(setFormModal({ value: false }));
    };
  }, [dispatch]);
  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {formModel ? (
          <>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                variant="subtile2"
                sx={{
                  color: "#1976d2",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline", color: "#1976d2" },
                }}
                onClick={(e) => {
                  dispatch(setFormModal({ value: false }));
                }}
              >
                <ArrowBackIcon sx={{ mb: 0.5, mr: 1, fontSize: "18px" }} />
                News & Events
              </Typography>
              <Typography variant="subtile1" component="p" color="text.primary">
                Form
              </Typography>
            </Breadcrumbs>
            <NewsForm />
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                gap: {
                  xs: 2,
                  md: 0,
                },
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4" component="h5">
                {" News & Events"}
              </Typography>

              <Tooltip title="Add new row" arrow placement="top">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<AddIcon />}
                  className="py-2 px-md-5 rounded-1 fw-bold btn-blue"
                  onClick={() => {
                    dispatch(setFormModal({ value: true }));
                    dispatch(setID({ value: "" }));
                  }}
                >
                  Add
                </Button>
              </Tooltip>
            </Box>
            <Box>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={60} />
              ) : (
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          background: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        <TableCell sx={{ color: "#fff" }}>Image</TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          Title
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="right">
                          Created Date
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="right">
                          Edit
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="right">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {newsCollection.map((row, i) => (
                        <StyledTableRow
                          className="shadow-sm "
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <img
                              src={row.imageURL[0]?.Location}
                              alt=".."
                              style={{ width: "70px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.heading}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.date}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <IconButton
                              onClick={() => {
                                dispatch(setID({ value: row.id }));
                                dispatch(setDeleteKey({ value: row.imageURL }));
                                dispatch(setHeading({ value: row.heading }));
                                dispatch(setDate({ value: row.date }));
                                dispatch(setBody({ value: row.body }));
                                dispatch(setFormModal({ value: true }));
                              }}
                            >
                              <CreateIcon
                                sx={{ color: "#1976d2", cursor: "pointer" }}
                              />
                            </IconButton>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {id === row.id && Buttonloading ? (
                              <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                              ></LoadingButton>
                            ) : (
                              <IconButton
                                onClick={() => {
                                  dispatch(setID({ value: row.id }));
                                  dispatch(
                                    setDeleteKey({ value: row.imageURL })
                                  );
                                  dispatch(deleteNewsAsync({}));
                                }}
                              >
                                <DeleteIcon
                                  sx={{ color: "#5D5D5D", cursor: "pointer" }}
                                />
                              </IconButton>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </>
        )}
      </Container>
    </>
  );
}
