import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function createEvents(title, date, content, gallery, website) {
  const createDoc = httpsCallable(functions, "createEvents");
  return createDoc({
    title,
    date,
    content,
    gallery,
    website,
  });
}

export function getEvents(sessionToken, website) {
  const getDoc = httpsCallable(functions, "getEvents");
  return getDoc({ sessionToken, website });
}

export function deleteEvents(id, deleteKey, website) {
  const getDoc = httpsCallable(functions, "deleteEvents");
  return getDoc({ id, deleteKey, website });
}

export function updateEvents(
  id,
  deleteKey,
  title,
  date,
  content,
  gallery,
  website
) {
  const getDoc = httpsCallable(functions, "updateEvents");
  return getDoc({
    id,
    deleteKey,
    title,
    date,
    content,
    gallery,
    website,
  });
}
