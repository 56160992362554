import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/SaveAs";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  getvideoGalleryAsync,
  setVideoLink,
  videoGalleryState,
} from "./reduxSlice";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 375,
  maxWidth: 450,
  bgcolor: "background.paper",
  m: 0,
  border: 0,
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

function createData(title, createdDate) {
  return { title, createdDate };
}

const rows = [
  createData("https://www.youtube.com/embed/TkUlLVMslKc", "25-feb-2023"),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    // fontWeight: "bold",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function Video() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { id, videoLink } = useSelector(videoGalleryState);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(getvideoGalleryAsync({}));
  };
  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 0,
            },
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="h5">
            {" Video Gallery"}
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              background: "grey",
              ml: "auto",
              mr: 3,
              display: {
                xs: "none",
                md: "block",
              },
            }}
          ></Divider>
          <Tooltip title="Add new row" arrow placement="top">
            <Button
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}
              className="py-2 px-md-5 rounded-1 fw-bold btn-blue"
              onClick={handleOpen}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow
                  sx={{ background: "#000", textTransform: "capitalize" }}
                >
                  {/* <TableCell sx={{ color: "#fff" }}>Image</TableCell> */}
                  <TableCell sx={{ color: "#fff" }} align="left">
                    Link
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="center">
                    Created Date
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Edit
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <StyledTableRow
                    className="shadow-sm "
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <StyledTableCell component="th" scope="row">
                      <img
                        src="https://images.unsplash.com/photo-1678737046425-c85475e7855f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt=".."
                        style={{ width: "50px" }}
                      />
                    </StyledTableCell> */}
                    <StyledTableCell align="left">{row.title}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.createdDate}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <CreateIcon
                        sx={{ color: "#1976d2", cursor: "pointer" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <DeleteIcon
                        sx={{ color: "#5D5D5D", cursor: "pointer" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Grid container spacing={2} component="form">
            <Grid item xs={12} className="d-flex justify-content-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} component="form" onSubmit={handleClick}>
              <Typography variant="p" component="div" sx={{ my: 2 }}>
                Enter the youtube link :
              </Typography>
              <TextField
                required
                placeholder="TkUlLVMslKc"
                id="youtubeLink"
                label="Link"
                fullWidth
                value={videoLink ? videoLink : ""}
                helperText="you will find link under : youtube vedio → share → embed → src='https://www.youtube.com/embed/TkUlLVMslKc'"
                className="mb-4"
                onChange={(e) => {
                  dispatch(setVideoLink({ value: e.target.value }));
                }}
              />
            </Grid>

            <Grid item xs={12} className="d-flex justify-content-end px-0">
              {id ? (
                <Button
                  variant="contained"
                  type="button"
                  startIcon={<SaveIcon />}
                  // onClick={handleEdit}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                  onClick={handleClick}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
