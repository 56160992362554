import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Roots from "./components/Routes/Roots";
import Login from "./components/Components/Login/App";
function App() {
  return (
    <>
      <Routes>
        <Route>
          <Route path="*" element={<Roots />} />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
