import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ArticleIcon from "@mui/icons-material/Article";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CollectionsIcon from "@mui/icons-material/Collections";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { Box, ListItemIcon, ListItemText, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardTab } from "../Dashboard/reduxSlice";
import { profileState } from "../Profile/reduxSlice";
import { getUserDetailsAsync } from "../Profile/reduxSlice";

export default function ListItem(props) {
  const [selected, setSelected] = React.useState("profile");
  const dispatch = useDispatch();
  const { userProfile } = useSelector(profileState);
  const sessionToken = window.localStorage.getItem("sessionToken");
  React.useEffect(() => {
    dispatch(getUserDetailsAsync({}));
  }, [dispatch]);
  return (
    <React.Fragment>
      <List
        component="nav"
        sx={{
          height: "calc(100vh - 70px)",
          overflow: props.open ? "scroll" : "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: 2,
            mb: 1,
          }}
        >
          <Typography
            variant="subtitle1"
            component="h6"
            className="mb-2 fw-semibold ps-2 text-dark"
            sx={{
              textTransform: "uppercase",
              fontSize: "14px",
              display: props.open ? "block" : "none",
            }}
          >
            Getting Started
          </Typography>
          <ListItemButton
            sx={{
              cursor: "pointer",
              "&:hover": {
                borderRight: "2px solid #007bff",
                background: "#0d6efd12",
              },
              borderRight:
                selected === "Profile"
                  ? "2px solid #007bff"
                  : "2px solid transparent",
              background: selected === "Profile" ? "#0d6efd12" : null,
            }}
            onClick={() => {
              dispatch(setDashboardTab({ tab: "Profile" }));
              setSelected("Profile");
            }}
          >
            <ListItemIcon>
              <PersonIcon sx={{ fontSize: "22px" }} />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
          <ListItemButton
            sx={{
              cursor: "pointer",
              "&:hover": {
                borderRight: "2px solid #007bff",
                background: "#0d6efd12",
              },
              borderRight:
                selected === "Settings"
                  ? "2px solid #007bff"
                  : "2px solid transparent",
              background: selected === "Settings" ? "#0d6efd12" : null,
            }}
            onClick={() => {
              dispatch(setDashboardTab({ tab: "Settings" }));
              setSelected("Settings");
            }}
          >
            <ListItemIcon>
              <SettingsIcon sx={{ fontSize: "22px" }} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
          {sessionToken === "8HXNc3i5dpe9aalW92NdbiIMKKe2" ? (
            <ListItemButton
              sx={{
                cursor: "pointer",
                "&:hover": {
                  borderRight: "2px solid #007bff",
                  background: "#0d6efd12",
                },
                borderRight:
                  selected === "User"
                    ? "2px solid #007bff"
                    : "2px solid transparent",
                background: selected === "User" ? "#0d6efd12" : null,
              }}
              onClick={() => {
                dispatch(setDashboardTab({ tab: "User" }));
                setSelected("User");
              }}
            >
              <ListItemIcon>
                <PersonIcon sx={{ fontSize: "22px" }} />
              </ListItemIcon>
              <ListItemText primary="User" />
            </ListItemButton>
          ) : (
            ""
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: 2,
            mb: 1,
          }}
        >
          <Typography
            variant="subtitle1"
            component="h6"
            className="mb-2 fw-semibold ps-2 text-dark"
            sx={{
              textTransform: "uppercase",
              fontSize: "14px",
              display: props.open ? "block" : "none",
            }}
          >
            Content
          </Typography>
          {userProfile?.permissions?.map((name, index) => {
            if (name === "News & Events") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <NewspaperIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Pages") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <ArticleIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Visitors") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <EventNoteIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Gallery") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <CollectionsIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Testimony") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <Diversity3Icon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Announcement") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <AnnouncementIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Youtube Embedded") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <YouTubeIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Devotion Timing") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <AccessTimeIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Daily Quote") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <FormatQuoteIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Video Gallery") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <VideoCameraBackIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            } else if (name === "Monthly Magazine") {
              return (
                <ListItemButton
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      borderRight: "2px solid #007bff",
                      background: "#0d6efd12",
                    },
                    borderRight:
                      selected === name
                        ? "2px solid #007bff"
                        : "2px solid transparent",
                    background: selected === name ? "#0d6efd12" : null,
                  }}
                  onClick={() => {
                    dispatch(setDashboardTab({ tab: name }));
                    setSelected(name);
                  }}
                >
                  <ListItemIcon>
                    <ImportContactsIcon sx={{ fontSize: "22px" }} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            }
          })}
        </Box>
      </List>
    </React.Fragment>
  );
}
