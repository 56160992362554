import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Alert,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/SaveAs";
import CloseIcon from "@mui/icons-material/Close";
import Compressor from "compressorjs";
import {
  galleryState,
  setFolder,
  setImage,
  setLink,
  setTitle,
  UploadFileAsync,
} from "../reduxSlice";
import { useDispatch, useSelector } from "react-redux";

const ItemList = [
  {
    value: "gallery",
    label: "Gallery",
  },
  {
    value: "history",
    label: "History",
  },
  {
    value: "houses",
    label: "Houses",
  },
  {
    value: "psychaitric_home",
    label: "Psychaitric Home",
  },
  {
    value: "poor_students_home",
    label: "Poor Students Home",
  },
  {
    value: "sambhrama_hall",
    label: "Sambhrama Hall",
  },
  {
    value: "medical_care",
    label: "Medical Care",
  },
  {
    value: "spiritual_activities",
    label: "Spiritual Activities",
  },
  {
    value: "daily_chores",
    label: "Daily Chores",
  },
  {
    value: "museum",
    label: "Museum",
  },
  {
    value: "shrine",
    label: "Shrine",
  },
];

export default function GalleryForm(props) {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const { folder, files } = useSelector(galleryState);
  const onSelectFile = (event) => {
    const file = event.target.files[0];
    // Request will be sent from here in the future
    new Compressor(file, {
      quality: 0.8,
      success: async (compressedResult) => {
        const convertedFile = await convertToBase64(compressedResult);
        dispatch(
          setImage({ name: file.name, type: file.type, file: convertedFile })
        );
      },
    });
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const handleSubmit = () => {
    if (folder && files.name) {
      props.handleClose();
      dispatch(UploadFileAsync({}));
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Box className="w-100">
        <Grid container className=" d-flex flex-column gap-4">
          <Grid item xs={12} className="d-flex justify-content-end">
            <IconButton onClick={props.handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          {error ? (
            <Grid item xs={12}>
              <Alert severity="error">
                Please fill out all required fields
              </Alert>
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={12} className="p-0">
            <TextField
              id="outlined-select-currency"
              select
              label="Upload Image For"
              defaultValue=""
              className="w-100"
              required
              onChange={(e) => {
                dispatch(setFolder({ value: e.target.value }));
              }}
            >
              {ItemList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} className="p-0">
            <TextField
              inputProps={{
                maxLength: 40,
              }}
              id="outlined-basic"
              label="Title"
              placeholder="title for image"
              helperText="maximum word length 40 (leave empty if there's no title)"
              variant="outlined"
              className="w-100"
              onChange={(e) => {
                dispatch(setTitle({ value: e.target.value }));
              }}
            />
          </Grid>
          <Grid item xs={12} className="p-0">
            <TextField
              inputProps={{
                accept: "image/*",
              }}
              id="outlined-basic"
              type="file"
              variant="outlined"
              className="w-100"
              onChange={onSelectFile}
              required
              helperText="image ratio must be 4:3 or 1024 x 768"
            />
          </Grid>
          <Grid item xs={12} className="p-0">
            <TextField
              id="outlined-basic"
              label="Link"
              placeholder="/history"
              helperText="(leave empty if there's no link to redirect)"
              variant="outlined"
              className="w-100"
              onChange={(e) => {
                dispatch(setLink({ value: e.target.value }));
              }}
            />
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-end px-0">
            <Button
              variant="contained"
              type="button"
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
