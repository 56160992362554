import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export async function assignRole(
  email,
  password,
  permissions,
  firstName,
  lastName,
  website
) {
  const getDocIdofUser = httpsCallable(functions, "assignRole");
  return getDocIdofUser({
    email,
    password,
    permissions,
    firstName,
    lastName,
    website,
  });
}

export function getUserProfile(sessionToken,website) {
  const getUserProfileDoc = httpsCallable(functions, "getUserProfile");
  return getUserProfileDoc({ sessionToken ,website});
}

export function deleteUser(email,website) {
  const deleteUserDoc = httpsCallable(functions, "deleteUser");
  return deleteUserDoc({ email,website });
}

export function getUserDetails(UID,website){
  const getUserDoc = httpsCallable(functions, "getUserDetails");
  return getUserDoc({ UID,website });
}

export function updateUserPermissions(UID, permissions,website){
  const updateUserDoc = httpsCallable(functions, "updateUsePermissions");
  return updateUserDoc({ UID, permissions ,website});
}
