import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { loginUser, logoutUser, resetPassword } from "./reduxAPI";

const initialState = {
  email: "",
  password: "",
  rememberMe: true,
};


export const loginUserAsync = createAsyncThunk(
  "login/loginUser",
  async (
    {  navigate },
    { dispatch, getState, rejectWithValue }
  ) => { 
    try {
      const { email, password} = getState().login;
      const userCredential = await loginUser(email, password);
      console.log(userCredential.user)
      const user = userCredential.user.uid;
      navigate('/dashboard')
      window.localStorage.setItem("sessionToken", user)
      return user
    } catch (error) {
      if (error.name === "FirebaseError") {
        // dispatch(setErrorNotification({errorNotification: error.message}))
        console.log(
             error.code,error.message,
        )
      }
      return rejectWithValue(error);
    }
  }
);

export const loginoutUserAsync = createAsyncThunk(
  "login/loginoutUser",
  async (
    { all,navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await logoutUser({});
      window.localStorage.removeItem("sessionToken")
      navigate('/')
      return response
    } catch (error) {
      if (error.name === "FirebaseError") {
        // dispatch(setErrorNotification({errorNotification: error.message}))
        console.log(
             error.code,error.message,
        )
      }
      return rejectWithValue(error);
    }
  }
);

export const resetUserPasswordAsync = createAsyncThunk(
  "user/resetPassword",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {email} = getState().login
      const response = await resetPassword(email);
      return response

    } catch (error) {
      if (error.name === "FirebaseError") {
        // dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setPassword: (state, action) => {
      state.password = action.payload.password;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.email = "";
        state.password = "";
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(resetUserPasswordAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(resetUserPasswordAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.email = "";
      })
      .addCase(resetUserPasswordAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loginoutUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginoutUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.email = "";
        state.password = "";
      })
      .addCase(loginoutUserAsync.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const { setEmail, setPassword } = loginSlice.actions;
export const loginState = (state) => state.login;
export default loginSlice.reducer;
