import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Skeleton,
  Alert,
} from "@mui/material";
import * as React from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import UserForm from "./component/UserForm";
import SkeletonComponent from "./component/skeleton";
import {
  clearState,
  getUserProfileAsync,
  newUserState,
  setModalOpen,
  setUserPermission,
  setSelectedEmail
} from "./reduxSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteUserAsync, setSelectedUser } from "./reduxSlice";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  minWidth: 375,
  maxWidth: 450,
  bgcolor: "background.paper",
  m: 0,
  border: 0,
  boxShadow: 24,
  p: 4,
  px: 5,
  borderRadius: 2,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function User() {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const { userCollection, loading, modalOpen } = useSelector(newUserState);
  const handleOpen = (e) => {
    dispatch(setModalOpen({})); //table data
  };
  const handleClose = () => {
    dispatch(setModalOpen({}));
  };
  const handleAdd = () => {
    dispatch(clearState({}));
    dispatch(setModalOpen({}));
  };

  React.useEffect(() => {
    dispatch(getUserProfileAsync({}));
    return () => {
      dispatch(clearState({}));
    };
  }, [dispatch]);

  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {loading ? (
          <SkeletonComponent />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                gap: {
                  xs: 2,
                  md: 0,
                },
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4" component="h5">
                {" User"}
              </Typography>

              <Tooltip title="Add new user" arrow placement="top">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<AddIcon />}
                  className="py-2 px-md-5 rounded-1 fw-bold btn-blue text-wrap"
                  onClick={handleAdd}
                >
                  Add User
                </Button>
              </Tooltip>
            </Box>
            <Box>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        background: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <TableCell sx={{ color: "#fff" }}>Sr</TableCell>
                      <TableCell sx={{ color: "#fff" }} align="left">
                        Email
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }} align="right">
                        Edit
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }} align="right">
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userCollection?.map((row, i) => (
                      <StyledTableRow
                        className="shadow-sm "
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {i + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            onClick={() => {
                              dispatch(setSelectedUser({ value: row.uid }));
                              dispatch(
                                setUserPermission({ value: row.permissions })
                              );
                              handleOpen();
                            }}
                          >
                            <CreateIcon
                              sx={{ color: "#1976d2", cursor: "pointer" }}
                            />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            onClick={() => {
                              dispatch(setSelectedEmail({ value: row.email }));
                              dispatch(deleteUserAsync({}));
                            }}
                          >
                            <DeleteIcon
                              sx={{ color: "#5D5D5D", cursor: "pointer" }}
                            />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Container>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <UserForm handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
}
