import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function createVideoGallery(videoLink, website) {
  const createVideoGallery = httpsCallable(functions, "createYoutube");
  return createVideoGallery({ videoLink, website });
}

export function getVideoGallery(sessionToken, website) {
  const getVideoGallery = httpsCallable(functions, "getLastYoutube");
  return getVideoGallery({ sessionToken, website });
}

export function deleteVideoGallery(sessionToken, website) {
  const deleteVideoGallery = httpsCallable(functions, "getLastYoutube");
  return deleteVideoGallery({ sessionToken, website });
}
