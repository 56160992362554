import * as React from "react";
import {
  Box,
  Container,
  Typography,
  Tab,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CreateIcon from "@mui/icons-material/Create";
import PersonIcon from "@mui/icons-material/Person";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  profileState,
  setFirstName,
  setLastName,
  setUserName,
  updateUserDetailsAsync,
} from "./reduxSlice";

import SkeletonComponent from "./components/skeleton";

export default function ProfilePage() {
  const dispatch = useDispatch();
  const { firstName, lastName, userName, userProfile, updateLoading, loading } =
    useSelector(profileState);
  const [value, setValue] = React.useState("1");
  const handleChange = (e) => {
    e.preventDefault();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserDetailsAsync({}));
  };

  return (
    <>
      <Container className="p-md-5 d-flex flex-column gap-2">
        {loading ? (
          <SkeletonComponent />
        ) : (
          <>
            <Typography variant="h4" component="h5">
              {" Update User"}
            </Typography>
            <TabContext value={value}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="w-100 d-flex justify-content-start align-items-start"
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={
                      <Box className="d-flex gap-1 align-items-center">
                        <CreateIcon sx={{ fontSize: "18px" }} />
                        <Typography
                          variant="subtitle2"
                          component="p"
                          color="inherit"
                          sx={{ fontSize: "13px" }}
                        >
                          {"General"}
                        </Typography>
                      </Box>
                    }
                    value="1"
                    className="ps-0"
                  />
                </TabList>
              </Box>
              <TabPanel value="1" className="w-100 px-0">
                <Grid
                  container
                  gap={2}
                  className="g-2 m-0 p-0"
                  component="form"
                >
                  <Grid item xs={12} className="p-0">
                    <TextField
                      autoComplete="off"
                      placeholder="user name"
                      label={
                        <Box className="d-flex gap-1 align-items-center">
                          <CreateIcon sx={{ fontSize: "20px" }} />
                          <Typography
                            variant="subtitle2"
                            component="p"
                            color="inherit"
                          >
                            {"  User Name"}
                          </Typography>
                        </Box>
                      }
                      id="userName"
                      name="userName"
                      variant="outlined"
                      className="w-100"
                      defaultValue={userProfile ? userName : ""}
                      onChange={(e) => {
                        dispatch(setUserName({ value: e.target.value }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="p-0">
                    <Box className="d-flex gap-2">
                      <TextField
                        inputProps={{
                          autoComplete: "none",
                        }}
                        label={
                          <Box className="d-flex gap-2 align-items-center">
                            <PersonIcon sx={{ fontSize: "20px" }} />
                            <Typography
                              variant="subtitle2"
                              component="p"
                              color="inherit"
                            >
                              {"Name"}
                            </Typography>
                          </Box>
                        }
                        placeholder="name"
                        id="name"
                        name="name"
                        type="text"
                        variant="outlined"
                        className="w-100"
                        defaultValue={userProfile ? firstName : ""}
                        onChange={(e) => {
                          dispatch(setFirstName({ value: e.target.value }));
                        }}
                      />
                      <TextField
                        inputProps={{
                          autoComplete: "none",
                        }}
                        label={
                          <Box className="d-flex gap-1 align-items-center">
                            <PersonIcon sx={{ fontSize: "20px" }} />
                            <Typography
                              variant="subtitle2"
                              component="p"
                              color="inherit"
                            >
                              {"Surname"}
                            </Typography>
                          </Box>
                        }
                        placeholder="surname"
                        id="surname"
                        name="surname"
                        type="text"
                        variant="outlined"
                        className="w-100"
                        defaultValue={userProfile ? lastName : ""}
                        onChange={(e) => {
                          dispatch(setLastName({ value: e.target.value }));
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} className="p-0">
                    {updateLoading ? (
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Save
                      </LoadingButton>
                    ) : (
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit}
                        startIcon={<SaveIcon />}
                        className="py-2 px-5 rounded-1 fw-bold btn-blue"
                      >
                        Save
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </>
        )}
      </Container>
    </>
  );
}
