import React from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Popover,
  IconButton,
} from "@mui/material";
import MUIRichTextEditor from "mui-rte";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { convertToHTML } from "draft-convert";
import Compressor from "compressorjs";
import dayjs from "dayjs";
import {
  setTitle,
  setDate,
  eventState,
  setImage,
  createEventsAsync,
  updateEventsAsync,
  clearState,
  setContent,
  setEditorUpload,
} from "./reduxSlice";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BackupIcon from "@mui/icons-material/Backup";
import { makeStyles } from "@mui/styles";

import { uploadImageToServer } from "../News/reduxAPI";

// style
const cardPopverStyles = makeStyles({
  root: {
    padding: 10,
    maxWidth: 350,
  },
  textField: {
    width: "100%",
  },
  input: {
    display: "none",
  },
});

// upload image
const uploadImage = (image) => {
  return new Promise(async (resolve, reject) => {
    const url = await uploadImageToServer(image);
    if (!url) {
      reject();
      return;
    }
    resolve({
      data: {
        url: url.data,
        width: 300,
        height: 200,
        alignment: "left", // or "center", "right"
        type: "image", // or "video"
      },
    });
  });
};
//image Popover
const UploadImagePopover = (props) => {
  const classes = cardPopverStyles(props);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    anchor: null,
    isCancelled: false,
  });
  const [data, setData] = React.useState({});
  const handleChange = (event) => {
    let file = event.target.files[0];
    let name = file.name;
    let type = file.type;
    new Compressor(file, {
      quality: 0.8,
      success: async (compressedResult) => {
        const convertedFile = await convertToBase64(compressedResult);
        const files = {
          img: convertedFile,
          fileName: name,
          fileType: type,
        };
        dispatch(setEditorUpload({ value: files }));
      },
    });

    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };
    setData({
      ...data,
      file,
    });
  };

  React.useEffect(() => {
    setState({
      anchor: props.anchor,
      isCancelled: false,
    });
    setData({
      file: undefined,
    });
  }, [props.anchor]);

  return (
    <Popover
      anchorEl={state.anchor}
      open={state.anchor !== null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={10}>
          <TextField
            className={classes.textField}
            disabled
            value={data.file?.name || ""}
            placeholder="Click icon to attach image"
          />
        </Grid>
        <Grid item xs={2}>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleChange}
          />
          <label htmlFor="contained-button-file">
            <IconButton
              color="primary"
              aria-label="upload image"
              component="span"
            >
              <AttachFileIcon />
            </IconButton>
          </label>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            onClick={() => {
              setState({
                anchor: null,
                isCancelled: true,
              });
            }}
          >
            <CloseIcon />
          </Button>
          <Button
            onClick={() => {
              setState({
                anchor: null,
                isCancelled: false,
              });
              props.onSubmit(data, !state.isCancelled);
            }}
          >
            <DoneIcon />
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default function EventsForm() {
  const [error, setError] = React.useState(false);
  const ref = React.useRef(null);
  const [anchor, setAnchor] = React.useState(null);

  const [defaultValueOfEditor, setDefaultValueOfEditor] =
    React.useState(undefined);

  const dispatch = useDispatch();
  const { id, Buttonloading, title, date, content, gallery, editorUpload } =
    useSelector(eventState);

  const handleFileUpload = (file) => {
    ref.current?.insertAtomicBlockAsync(
      "IMAGE",
      uploadImage(editorUpload),
      "Uploading now..."
    );
  };

  const handleChange = async (event) => {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      let name = files[i].name;
      let type = files[i].type;
      new Compressor(files[i], {
        quality: 0.8,
        success: async (compressedResult) => {
          const convertedFile = await convertToBase64(compressedResult);
          const object = {
            img: convertedFile,
            fileName: name,
            fileType: type,
          };
          dispatch(
            setImage({
              index: i,
              value: object,
            })
          );
        },
      });
    }

    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (title && date && gallery) {
      dispatch(createEventsAsync({}));
      setError(false);
    } else {
      setError(true);
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(updateEventsAsync({}));
    setError(false);
  };
  React.useEffect(() => {
    if (id && content) {
      const contentHTML = convertFromHTML(content);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const raw = convertToRaw(state);
      const { blocks, entityMap } = raw;
      const fixedBlocks = blocks.map((block) => {
        return {
          ...block,
          type: block.text === "📷" ? "atomic" : block.type,
        };
      });
      const fixedEntityMap = {};
      for (const [key, value] of Object.entries(entityMap)) {
        if (value.type === "IMAGE") {
          value.data.url = value.data.src;
        }

        fixedEntityMap[key] = value;
      }
      setDefaultValueOfEditor(
        JSON.stringify({
          blocks: fixedBlocks,
          entityMap: fixedEntityMap,
        })
      );
    }
    return () => {
      dispatch(clearState({}));
    };
  }, [id, dispatch]);

  const editorChanges = (editorState) => {
    const html = convertToHTML({
      styleToHTML: (style) => {
        if (style === "BOLD") {
          return <span style={{ color: "blue" }} />;
        }
      },
      blockToHTML: (block) => {
        if (block.type === "PARAGRAPH") {
          return <p />;
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === "IMAGE") {
          return (
            <img
              src={entity.data.url}
              alt="img"
              width={entity.data.width}
              height={entity.data.height}
            />
          );
        } else if (entity.type === "LINK") {
          return <a href={entity.data.url}>{originalText}</a>;
        }
        return originalText;
      },
    })(editorState.getCurrentContent());

    dispatch(
      setContent({
        content: html,
      })
    );
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography variant="p" component="div" sx={{ mb: 1 }}>
            {"Upload Multiple Image ( image ratio must be 4:3 or 1024 x 768 )"}
          </Typography>
          <TextField
            inputProps={{
              multiple: "multiple",
              accept: "image/*",
            }}
            id="outlined-basic"
            type="file"
            variant="outlined"
            className="w-100"
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p" component="div" sx={{ mb: 1 }}>
            Select Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} className="w-100">
            <DatePicker
              className="w-100"
              defaultValue={id ? dayjs(date) : dayjs(new Date("2023-03-25"))}
              onChange={(e) => {
                dispatch(setDate({ value: e.toString() }));
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          {error ? (
            <Alert severity="error">Please fill out all required fields</Alert>
          ) : (
            ""
          )}
          <Typography variant="p" component="div" sx={{ mb: 1 }}>
            Enter the Heading
          </Typography>
          <TextField
            required
            defaultValue={id ? title : ""}
            id="outlined-required"
            label="Heading"
            fullWidth
            onChange={(e) => {
              dispatch(setTitle({ title: e.target.value }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p" component="div" sx={{ mb: 1 }}>
            {"Enter the Content"}
          </Typography>
          <Box
            sx={{
              minHeight: "250px",
              border: 2,
              borderColor: "#e9e9e9",
              px: 2,
              my: 2,
            }}
          >
            <UploadImagePopover
              anchor={anchor}
              onSubmit={(data, insert) => {
                if (insert && data.file) {
                  handleFileUpload(data.file);
                }
                setAnchor(null);
              }}
            />
            <MUIRichTextEditor
              label="Type something here..."
              defaultValue={id ? defaultValueOfEditor : ""}
              ref={ref}
              controls={[
                "title",
                "bold",
                "italic",
                "underline",
                "undo",
                "redo",
                "numberList",
                "bulletList",
                "clear",
                // "media",
                // "upload-image",
              ]}
              customControls={[
                {
                  name: "upload-image",
                  icon: <BackupIcon />,
                  type: "callback",
                  onClick: (_editorState, _name, anchor) => {
                    setAnchor(anchor);
                  },
                },
              ]}
              onChange={editorChanges}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {Buttonloading ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            Save
          </LoadingButton>
        ) : id ? (
          <Button
            variant="contained"
            className="px-md-5"
            type="submit"
            onClick={handleUpdate}
          >
            {"  Update"}
          </Button>
        ) : (
          <Button
            variant="contained"
            className="px-md-5"
            type="submit"
            onClick={handleSubmit}
          >
            {"  Submit"}
          </Button>
        )}
      </Grid>
    </>
  );
}
