import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardTab: "Profile",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardTab: (state, action) => {
      state.dashboardTab = action.payload.tab;
    },
  },
});

export const {setDashboardTab } = dashboardSlice.actions;
export const dashboardState = (state) => state.dashboard;
export default dashboardSlice.reducer;
