import * as React from "react";
import { Skeleton, Box } from "@mui/material";

export default function App() {
  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton
        animation="wave"
        variant="text"
        sx={{ fontSize: "3rem" }}
        width={250}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={150}
        height={50}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={50}
        sx={{ my: 1 }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 1,
        }}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ width: { xs: "100%", md: "50%" },mb: ".5" }}
          height={50}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={50}
          sx={{ width: { xs: "100%", md: "50%" },mb: ".5"  }}
        />
      </Box>
    </Box>
  );
}
