import { httpsCallable } from "firebase/functions";
import { functions  } from "../../../firebase/config";

export function getUserDetails(UID,website){
    const getUserDoc = httpsCallable(functions, "getUserDetails");
    return getUserDoc({ UID,website });
  }
  export function updateUserDetails(uid, userName, firstName, lastName,website){
    const updateUserDoc = httpsCallable(functions, "updateUserDetails");
    return updateUserDoc({ uid, userName, firstName, lastName ,website});
  }